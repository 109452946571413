import { VueReCaptcha } from 'vue-recaptcha-v3';

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.use(VueReCaptcha, {
        siteKey: '6LcWRukpAAAAAAJPjCD0z8nG92ZZsJjwgmgQO1fN',
        loaderOptions: {
            autoHideBadge: true,
            explicitRenderParameters: {
                badge: 'rightleft',
            },
        },
    });
});
