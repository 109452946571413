import revive_payload_client_4sVQNw7RlN from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/opt/build/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_eskviOYyLt from "/opt/build/repo/node_modules/nuxt-vue3-google-signin/dist/runtime/plugin.mjs";
import plugin_Jozdw60ZsE from "/opt/build/repo/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import primevue_plugin_egKpok8Auk from "/opt/build/repo/.nuxt/primevue-plugin.mjs";
import plugin_client_8fz26Kfk5j from "/opt/build/repo/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import plugin_AUP22rrBAc from "/opt/build/repo/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import stripe_4wGCNFIyGT from "/opt/build/repo/plugins/stripe.js";
import listener_6hzxdhe2fb from "/opt/build/repo/plugins/listener.js";
import google_recaptch_npWDGd2rbS from "/opt/build/repo/plugins/google-recaptch.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_eskviOYyLt,
  plugin_Jozdw60ZsE,
  primevue_plugin_egKpok8Auk,
  plugin_client_8fz26Kfk5j,
  plugin_AUP22rrBAc,
  stripe_4wGCNFIyGT,
  listener_6hzxdhe2fb,
  google_recaptch_npWDGd2rbS
]