<template>
  <NuxtLayout>
    <NuxtPage class="font-poppins" />
  </NuxtLayout>
</template>
<script setup>
import { useRoute } from '#app';

useHead({
  htmlAttrs:{
    lang:'en-US',
  },
  link: [
    { rel: 'dns-prefetch', href: '//ik.imagekit.io' },
    { rel: 'dns-prefetch', href: '//fonts.googleapis.com' },
    // { rel: 'dns-prefetch', href: '//api.hireheroes.com' },
    // { rel: 'dns-prefetch', href: '//hireheroes-backend-v2.onrender.com' },
    // { rel: 'preconnect', href: '//hireheroes-backend-v2.onrender.com', crossorigin: 'anonymous' },
    { rel: 'preconnect', href: '//ik.imagekit.io', crossorigin: 'anonymous' },
    { rel: 'preconnect', href: '//api.hireheroes.com', crossorigin: 'anonymous' },
    { rel: 'stylesheet', href: '//fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;&display=swap'},
  ],
})

// const config = useRuntimeConfig();
const user = useUserStore();
const walletStore = useWalletStore();
const route = useRoute(); // Get the current route

// Access the `accessMode` query parameter
const accessMode = route.query.accessMode;
if(accessMode == 'preview') {
  user.setPreviewMode(true)
}

console.log('Access Mode:', accessMode);

onBeforeUnmount(() => {
  console.log("unmounting", walletStore.intervalId);
});

onMounted(()=>{
  console.log('App OnMounted')
})

</script>
