import { default as activitylcsm0meQh4Meta } from "/opt/build/repo/pages/activity.vue?macro=true";
import { default as calendarqK8xfIK65VMeta } from "/opt/build/repo/pages/calendar.vue?macro=true";
import { default as index8KdCOhjwhDMeta } from "/opt/build/repo/pages/chat/index.vue?macro=true";
import { default as _91id_93ns1ngcWsZ9Meta } from "/opt/build/repo/pages/complete-registration/[id].vue?macro=true";
import { default as forgot_45password6Wrw3WyRqiMeta } from "/opt/build/repo/pages/forgot-password.vue?macro=true";
import { default as help_45desktqxkkee1HUMeta } from "/opt/build/repo/pages/help-desk.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as loginBXD9sq3T3sMeta } from "/opt/build/repo/pages/login.vue?macro=true";
import { default as _91id_935vqvNScfLIMeta } from "/opt/build/repo/pages/project/[id].vue?macro=true";
import { default as indexnpobEWMjMtMeta } from "/opt/build/repo/pages/project/index.vue?macro=true";
import { default as projectGFOWP00jLxMeta } from "/opt/build/repo/pages/project.vue?macro=true";
import { default as _91id_93nxdnqNCDutMeta } from "/opt/build/repo/pages/reset-password/[id].vue?macro=true";
import { default as settings6ZNFu1CSghMeta } from "/opt/build/repo/pages/settings.vue?macro=true";
import { default as signupQ6T3vUFXXDMeta } from "/opt/build/repo/pages/signup.vue?macro=true";
import { default as training_45videosBep8SUN95KMeta } from "/opt/build/repo/pages/training-videos.vue?macro=true";
import { default as walletsV4IVfc4BfMeta } from "/opt/build/repo/pages/wallet.vue?macro=true";
export default [
  {
    name: "activity",
    path: "/activity",
    meta: activitylcsm0meQh4Meta || {},
    component: () => import("/opt/build/repo/pages/activity.vue").then(m => m.default || m)
  },
  {
    name: "calendar",
    path: "/calendar",
    meta: calendarqK8xfIK65VMeta || {},
    component: () => import("/opt/build/repo/pages/calendar.vue").then(m => m.default || m)
  },
  {
    name: "chat",
    path: "/chat",
    meta: index8KdCOhjwhDMeta || {},
    component: () => import("/opt/build/repo/pages/chat/index.vue").then(m => m.default || m)
  },
  {
    name: "complete-registration-id",
    path: "/complete-registration/:id()",
    component: () => import("/opt/build/repo/pages/complete-registration/[id].vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/opt/build/repo/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "help-desk",
    path: "/help-desk",
    meta: help_45desktqxkkee1HUMeta || {},
    component: () => import("/opt/build/repo/pages/help-desk.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexlaFeZuYZkUMeta || {},
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginBXD9sq3T3sMeta || {},
    component: () => import("/opt/build/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: projectGFOWP00jLxMeta?.name,
    path: "/project",
    component: () => import("/opt/build/repo/pages/project.vue").then(m => m.default || m),
    children: [
  {
    name: "project-id",
    path: ":id()",
    meta: _91id_935vqvNScfLIMeta || {},
    component: () => import("/opt/build/repo/pages/project/[id].vue").then(m => m.default || m)
  },
  {
    name: "project",
    path: "",
    meta: indexnpobEWMjMtMeta || {},
    component: () => import("/opt/build/repo/pages/project/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "reset-password-id",
    path: "/reset-password/:id()",
    component: () => import("/opt/build/repo/pages/reset-password/[id].vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    meta: settings6ZNFu1CSghMeta || {},
    component: () => import("/opt/build/repo/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupQ6T3vUFXXDMeta || {},
    component: () => import("/opt/build/repo/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: "training-videos",
    path: "/training-videos",
    meta: training_45videosBep8SUN95KMeta || {},
    component: () => import("/opt/build/repo/pages/training-videos.vue").then(m => m.default || m)
  },
  {
    name: "wallet",
    path: "/wallet",
    meta: walletsV4IVfc4BfMeta || {},
    component: () => import("/opt/build/repo/pages/wallet.vue").then(m => m.default || m)
  }
]