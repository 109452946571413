import { loadStripe } from '@stripe/stripe-js';

// export default function (context, inject) {
//   const stripePromise = loadStripe('pk_test_51P9KlfEMH6oiQ1VpxOagyZeWI1dv1hQjDIozINTeJ0ta8s6jbkGiT1KxDCVdfZ5Dr3QSWkQW1lBmJYf9Z4zvQgcN00j8dx6pFu');

//   inject('stripe', stripePromise);
// }


import { defineNuxtPlugin,useRuntimeConfig } from '#app'

export default defineNuxtPlugin(async(nuxtApp) => {
  const config = useRuntimeConfig()

  const stripe = await loadStripe(config.public.stripe_publishable_key);
    console.log('Stripe : ',stripe)
  nuxtApp.provide('stripe', stripe)
})
