import { defineStore } from "pinia";

export const useWalletStore = defineStore('wallet',{
    state:()=>{
        return {
            wallet:null,
            balance:{},
            transactions:[],
            cards:[],
            pricing:[],
            intervalId:null
        }
    },
    actions:{
        setWallet(detail){
            this.wallet = detail
        },
        setSetIntervalId(id){
            this.intervalId = id
        },
        updateHours(minutes){
            this.wallet['amount']= minutes
        },
        setTranscations(transactions){
            this.transactions = transactions
        },
        setCards(cards){
            this.cards = this.sortCardsByDefault(cards)
        },
        addCard(card){
            this.cards.unshift(card)
            this.sortCardsByDefault(this.cards)
        },
        removeCard(card){
            this.cards.unshift(card)
            this.sortCardsByDefault(this.cards)
        },
        setDeafaultCard(pm_id){
            const index = this.cards.findIndex(card=>card.id == pm_id)
            this.cards[index].isDefault = false
        },
        sortCardsByDefault(cards) {
            return cards.sort((a, b) => {
                if (a.isDefault && !b.isDefault) {
                    return -1;
                } else if (!a.isDefault && b.isDefault) {
                    return 1;
                } else {
                    return 0;
                }
            })
        },
        getSingleCard(pm_id){
            const index = this.cards.findIndex(card=>card.id == pm_id)
            return this.cards[index]
        },
        setPricing(pricing){
            this.pricing = pricing
        },
        setTransactions(txns){
            this.transactions = txns
        },
        autoPayToggle(result){
            this.wallet.autoPay = result
        },
        resetWalletStore(){
            this.wallet=null
            this.balance={}
            this.transactions=[]
            this.cards=[]
            this.pricing=[]
            this.intervalId=null
        }
    },
    getters:{
        
    }
})